<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </div>
</template>
<script>

export default ({
  // created () {
  //   this.$nextTick(() => {
  //     // eslint-disable-next-line no-new-func
  //     document.oncontextmenu = new Function('event.returnValue=false')
  //     // eslint-disable-next-line no-new-func
  //     document.onselectstart = new Function('event.returnValue=false')
  //     // eslint-disable-next-line no-new-func
  //     document.οnkeydοwn = new Function('event.returnValue=false')
  //   })
  //   document.onkeydown = function (e) {
  //     if (e && e.keyCode === 123) {
  //       e.returnValue = false
  //       return false
  //     }
  //   }
  // }
})
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
