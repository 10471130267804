import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/software',
    name: 'software',
    redirect: '/software/smartCity',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Software'),
    children: [{
      path: '/software/blockchain',
      name: 'blockchain',
      component: () =>
        import(/* webpackChunkName: "supplyList" */ '@/views/Software/blockchain.vue')
    },
    {
      path: '/software/smartCity',
      name: 'smartCity',
      component: () =>
        import(/* webpackChunkName: "identification" */ '@/views/Software/smartCity.vue')
    },
    {
      path: '/software/Industrial',
      name: 'Industrial',
      component: () =>
        import(/* webpackChunkName: "identification" */ '@/views/Software/Industrial.vue')
    },
    {
      path: '/software/supplychain',
      name: 'supplychain',
      component: () =>
        import(/* webpackChunkName: "identification" */ '@/views/Software/supplychain.vue')
    }
    ]
  },
  {
    path: '/aboutZC',
    name: 'aboutZC',
    redirect: '/aboutZC/company',
    component: () => import(/* webpackChunkName: "about" */ '@/views/aboutZC'),
    children: [{
      path: '/aboutZC/company',
      name: 'company',
      component: () =>
        import(/* webpackChunkName: "identification" */ '@/views/aboutZC/company.vue')
    },
    {
      path: '/aboutZC/contact',
      name: 'contact',
      component: () =>
        import(/* webpackChunkName: "supplyList" */ '@/views/aboutZC/contact.vue')
    }
    ]
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "about" */ '@/views/customer')
  },
  {
    path: '/human',
    name: 'human',
    component: () => import(/* webpackChunkName: "about" */ '@/views/human')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
