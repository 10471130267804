import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// 在main.js上进行挂载

export default new Vuex.Store({
  state: { // 存放需要共享的状态信息，使用时通过 $store.state.counter 即可拿到状态信息
    count: 0,
    navCount: 0
  },
  getters: { // 类似于计算属性，在数据展示前进行一些变化处理，具有缓存功能，能够提高运行效率,通过：$store.getters.powerCounter 获取：
  },
  mutations: { // 每个 mutation 都有一个字符串的事件类型和一个回调函数,回调函数就是进行状态更改的地方，并且它会接受 state 作为第一个参数,只能通过，mutation变更state数据，不可以直接操作state中的数据
    about (state, step) {
      state.count = step
    },
    software (state, step) {
      state.navCount = step
    }
  },
  actions: { // Action 提交的是 mutation，而不是直接变更状态。actions就提供了一种异步的形式提交mutations
  },
  modules: { // store 分割到模块,每个模块拥有自己的 state、mutation、action、getter、甚至是嵌套子模块——从上至下进行同样方式的分割：
  }
})
